import React, { useState } from "react"
import {Container,Button,ToggleButton, ToggleButtonGroup } from "react-bootstrap"
import LayoutOrig from "../../components/layout_en"
import Seo from "../../components/seo"




const Prices = () => {

    const [isChecked, setIsChecked] = useState(false);

    const handleToggle = () => setIsChecked(!isChecked);


    return(
        <LayoutOrig>

            <Seo
                titleTemplate={`Pricing: Offers Tailored to Your Needs | Trezorino`}
                title="Trezorino: Tailored Offers to Optimize Your Cash Management."
                description="Choose the offer that best suits your business, whether you're a freelancer, independent professional, small business, or SME..."
                lang="en"
            />
            <Container>
                <div className="container-fluid mt-5">
                    <div className="container p-5">
                        <h1 className="text-center mt-4 mb-6">Tailored offers to optimize your cash management.</h1>
                        <div  className="mb-4" style={{width:"20%", borderRadius:"100px"}}>
                            <ToggleButtonGroup className="switchbuttongroup" name="pricing" type="radio" defaultValue="true" onChange={handleToggle}>
                                <ToggleButton id="year" className={true ? "switchbutton-active" : "switchbutton"}  value="true">Yearly</ToggleButton>
                                <ToggleButton id="month" className={true ? "switchbutton-active" : "switchbutton"} value="false">Monthly</ToggleButton>
                            </ToggleButtonGroup>
                        </div>
                        <div className="row">

                            <div className="col-lg-4 col-md-12 mb-4">
                                <div className="card h-100 shadow-lg" style={{borderTopColor:"blue", borderWidth:"20px"}}>
                                    <div className="card-body">

                                        <div className="text-center p-3">
                                            <div className="card-title" style={{ fontSize:"38px"}}>Starter
                                            </div>
                                            <p className="card-text">Everything you need to start managing your treasury effectively.</p>
                                            <div>
                                                <span style={{ fontSize:"60px"}}>{isChecked ? 29 : 24}</span><span style={{ fontSize:"24px"}}> €</span> / month
                                            </div>
                                            <span>excl. VAT</span>
                                        </div>

                                    </div>
                                    <div className="card-body text-center">
                                        <Button  variant="try"  style={{width:"200px",fontSize:"14.4px", fontWeight:"500"}} href={`${process.env.APP_URL}/signup`}>Free Trial 14 days</Button>
                                    </div>
                                    <ul className="pt-4" style={{fontSize:"14px"}}>
                                        <li className="checkGreen"><p><span style={{color:"blue"}}>1</span> connected bank</p></li>
                                        <li className="checkGreen"><p>Bank balance monitoring</p></li>
                                        <li className="checkGreen"><p><span style={{color:"blue"}}>1</span> user</p></li>
                                        <li className="checkGreen"><p><span style={{color:"blue"}}>1</span> scénario</p></li>
                                        <li className="checkGreen"><p>Invoice centralization</p></li>
                                        <li className="checkGreen"><p>Automatic categorization</p></li>
                                        <li className="checkGreen"><p>Bank reconciliation</p></li>
                                        <li className="checkGreen"><p>Intelligent invoice capture : <span style={{color:"blue"}}>50</span> invoices / month</p></li>
                                        <li className="checkGreen"><p>Importing invoices from Excel</p></li>
                                        <li className="checkGreen"><p>Cash flow statement</p></li>
                                        <li className="checkGreen"><p>Forecast management</p></li>
                                        <li className="checkGreen"><p>Access to online support</p></li>
                                        <li className="close"><p>Dedicated account manager</p></li>
                                        <li className="close"><p>Multi-currency</p></li>
                                        <li className="close"><p>Multi-entity management</p></li>
                                        <li className="close"><p>User permissions management</p></li>
                                        <li className="close"><p>Automatic integration with external software</p></li>
                                        <li className="close"><p>Customized onboarding meeting</p></li>

                                    </ul>
                                    <div className="card-body text-center">
                                        <Button  variant="demo"  className="header-nav-button" style={{width:"200px",fontSize:"14.4px"}} href={`${process.env.APP_URL}/signup`}>Select Starter plan</Button>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-12 mb-4">
                                <div className="card h-100 shadow-lg" style={{borderTopColor:"blue", borderWidth:"20px"}}>
                                    <div className="card-body">

                                        <div className="text-center p-3">
                                            <div className="card-title" style={{ fontSize:"38px"}}>Pro
                                            </div>
                                            <p className="card-text">For small, dynamic businesses looking to manage their cash flow.</p>
                                            <div><span style={{ fontSize:"60px"}}>{isChecked ? 69 : 59}</span><span style={{ fontSize:"24px"}}> €</span> / month</div>
                                            <span>excl. VAT</span>
                                        </div>

                                    </div>
                                    <div className="card-body text-center">
                                        <Button  variant="try"  style={{width:"200px",fontSize:"14.4px", fontWeight:"500"}} href={`${process.env.APP_URL}/signup`}>Free Trial 14 days</Button>
                                    </div>
                                    <ul className="pt-4" style={{fontSize:"14px"}}>
                                        <li className="checkGreen"><p><span style={{color:"blue"}}>2</span> connected banks</p></li>
                                        <li className="checkGreen"><p>Bank balance monitoring</p></li>
                                        <li className="checkGreen"><p><span style={{color:"blue"}}>5</span> users</p></li>
                                        <li className="checkGreen"><p><span style={{color:"blue"}}>5</span> scénarios</p></li>
                                        <li className="checkGreen"><p>Invoice centralization</p></li>
                                        <li className="checkGreen"><p>Automatic categorization</p></li>
                                        <li className="checkGreen"><p>Bank reconciliation</p></li>
                                        <li className="checkGreen"><p>Intelligent invoice capture : <span style={{color:"blue"}}>100</span> invoices / month</p></li>
                                        <li className="checkGreen"><p>Importing invoices from Excel</p></li>
                                        <li className="checkGreen"><p>Cash flow statement</p></li>
                                        <li className="checkGreen"><p>Forecast management</p></li>
                                        <li className="checkGreen"><p>Access to online support</p></li>
                                        <li className="checkGreen"><p>Dedicated account manager</p></li>
                                        <li className="checkGreen"><p>Multi-currency</p></li>
                                        <li className="checkGreen"><p>Multi-entity management : <span style={{color:"blue"}}>2</span> company</p></li>
                                        <li className="checkGreen"><p>User permissions management</p></li>
                                        <li className="close"><p>Automatic integration with external software</p></li>
                                        <li className="close"><p>Customized onboarding meeting</p></li>
                                    </ul>
                                    <div className="card-body text-center">
                                        <Button  variant="demo"  className="header-nav-button" style={{width:"200px",fontSize:"14.4px"}} href={`${process.env.APP_URL}/signup`}>Select Pro plan</Button>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-12 mb-4">
                                <div className="card h-100 shadow-lg" style={{borderTopColor:"blue", borderWidth:"20px"}}>
                                    <div className="card-body">

                                        <div className="text-center p-3">
                                            <div className="card-title" style={{ fontSize:"38px"}}>Enterprise
                                            </div>
                                            <p className="card-text">A tailored service for small and medium-sized enterprises.</p>
                                            <div>
                                                <span style={{ fontSize:"60px"}}>{isChecked ? 239 : 199}</span><span style={{ fontSize:"24px"}}> €</span> / month
                                            </div>
                                            <span>excl. VAT</span>
                                        </div>

                                    </div>
                                    <div className="card-body text-center">
                                        <Button  variant="try"  style={{width:"200px",fontSize:"14.4px", fontWeight:"500"}} href={`${process.env.APP_URL}/signup`}>Free Trial 14 days</Button>
                                    </div>
                                    <ul className="pt-4" style={{fontSize:"14px"}}>
                                        <li className="checkGreen"><p><span style={{color:"blue"}}>3</span> connected banks</p></li>
                                        <li className="checkGreen"><p>Bank balance monitoring</p></li>
                                        <li className="checkGreen"><p><span style={{color:"blue"}}>10</span> users</p></li>
                                        <li className="checkGreen"><p><span style={{color:"blue"}}>10</span> scénarios</p></li>
                                        <li className="checkGreen"><p>Invoice centralization</p></li>
                                        <li className="checkGreen"><p>Automatic categorization</p></li>
                                        <li className="checkGreen"><p>Bank reconciliation</p></li>
                                        <li className="checkGreen"><p>Intelligent invoice capture : <span style={{color:"blue"}}>350</span> invoices / month</p></li>
                                        <li className="checkGreen"><p>Importing invoices from Excel</p></li>
                                        <li className="checkGreen"><p>Cash flow statement</p></li>
                                        <li className="checkGreen"><p>Forecast management</p></li>
                                        <li className="checkGreen"><p>Access to online support</p></li>
                                        <li className="checkGreen"><p>Dedicated account manager</p></li>
                                        <li className="checkGreen"><p>Multi-currency</p></li>
                                        <li className="checkGreen"><p>Multi-entity management : <span style={{color:"blue"}}>3</span> company</p></li>
                                        <li className="checkGreen"><p>User permissions management</p></li>
                                        <li className="checkGreen"><p>Automatic integration with external software</p></li>
                                        <li className="checkGreen"><p>Customized onboarding meeting</p></li>
                                    </ul>
                                    <div className="card-body text-center">
                                        <Button  variant="demo"  className="header-nav-button" style={{width:"200px",fontSize:"14.4px"}} href={`${process.env.APP_URL}/signup`}>Select Enterprise plan</Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Container>
        </LayoutOrig>
    )
}

export default Prices;
